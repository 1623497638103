<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormProductFeed
		v-else-if="!isNotFound"
		:id="feedEdit.id"
		:disabled-primary-button="isUpdating"
		:base-url="feedEdit.baseUrl"
		:status="feedEdit.status"
		:template="feedEdit.template"
		:last-updated="feedEdit.lastUpdated"
		:regenerate-time="feedEdit.regenerateTimes"
		:remarks="feedEdit.remarks"
		:updated-by="feedEdit.updatedBy"
		:product-condition="feedEdit.condition"
		is-deletable
	/>
	<Error404 v-else />
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState } from 'vuex';
import FormProductFeed from '@/components/FormProductFeed.vue';
import Error404 from '@/components/Error404.vue';

export default {
	name: 'ProductFeedCreate',
	components: {
		FormProductFeed,
		Error404,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('feeds', {
			feedEdit: (state) => state.edit.data,
			isUpdating: (state) => state.edit.isUpdating,
		}),

		isNotFound() {
			return isEmpty(this.feedEdit);
		},
	},
	async created() {
		const { ref: id } = this.$route.query;

		if (!id) {
			return;
		}

		this.isLoading = true;

		try {
			await this.getFeed(id);
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getFeed: 'feeds/getFeed',
		}),
	},
};
</script>
